import gql from 'graphql-tag'

export const Fair = gql`
  query fair($id: ID!) {
    fair(id: $id) {
      id
      slug
      name
      owner
      commission
      description
      startsAt
      endsAt
      storeIds
      active
      analytics
      image {
        id
        url
      }
      sponsors {
        id
        name
        image {
          id
          url
        }
      }
      banners {
        id
        linkableId
        height
        imageUrl
        color
        body
        title
        bannerType
      }
    }
  }
`

export const Fairs = gql`
  query fairs($search: String, $admin: Boolean) {
    fairs(search: $search, admin: $admin) {
      id
      name
      owner
      commission
      description
      startsAt
      endsAt
      storeIds
      active
      image {
        id
        url
      }
    }
  }
`

export const PublicFairs = gql`
  query publicFairs($search: String, $joined: Boolean) {
    publicFairs(search: $search, joined: $joined) {
      id
      name
      startsAt
      endsAt
      storeIds
      image {
        id
        url
      }
    }
  }
`

export const Banners = gql`
  query fairBanners($id: ID!, $resolution: String!) {
    fairBanners(id: $id, resolution: $resolution) {
      id
      linkableId
      height
      imageUrl
      color
      body
      title
    }
  }
`

export const Stores = gql`
  query storesInFair($id: ID!) {
    storesInFair(id: $id) {
      name
      stores {
        id
        name
        profile {
          id 
          slug
          picture {
            id
            url
          }
        }
      }
    }
  }
`

export const RandomStores = gql`
  query randomStoresInFair($id: ID!) {
    randomStoresInFair(id: $id) {
      id
      name
      profile {
        id 
        slug
        picture {
          id
          url
        }
      }
    }
  }
`
