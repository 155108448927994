<template>
  <section
    style="max-width: 982px !important; margin: 0 auto"
    class="pa-4"
    v-if="fair"
    >
    <v-snackbar
      v-model="alert"
      color="success darken-2"
      >
      <v-icon
        class="mt-n1"
        >
        mdi-check-circle
      </v-icon>

      Feria actualizada con éxito.
    </v-snackbar>

    <v-row
      >
      <v-col
        cols="12"
        md="4"
        >
        <info
          :fair="fair"
          @select="updateTab"
          @reload="reload"
          ></info>
      </v-col>

      <v-col
        cols="12"
        md="8"
        >
        <settings
          v-if="selected == 0"
          :fair="fair"
          @reload="reload"
          ></settings>

        <banners
          v-if="selected == 1"
          :fair="fair"
          @reload="reload"
          ></banners>

        <sponsors
          v-if="selected == 2"
          :fair="fair"
          @reload="reload"
          ></sponsors>

        <stores
          v-if="selected == 3"
          :fair="fair"
          @reload="reload"
          ></stores>

        <analytics
          v-if="selected == 4"
          :fair="fair"
          @reload="reload"
          ></analytics>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { Fair } from '@/graphql/queries/fairs/fairs'

const Info = () => import('@/components/fairs/Info')
const Banners = () => import('@/components/fairs/Banners')
const Settings = () => import('@/components/fairs/Settings')
const Sponsors = () => import('@/components/fairs/Sponsors')
const Stores = () => import('@/components/fairs/FairStores')
const Analytics = () => import('@/components/fairs/Analytics')

export default {
  data: () => ({
    fair: null,
    selected: 0,
    alert: false,
  }),

  created () {
    this.fetchFair()
  },

  methods: {
    fetchFair () {
      this.$apollo.query({
        query: Fair,
        variables: {
          id: this.$route.params.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.fair = res.data.fair
      })
    },

    updateTab (tab) {
      this.selected = tab
    },

    reload () {
      this.fetchFair()
      this.alert = true
    }
  },

  components: {
    Info,
    Banners,
    Settings,
    Sponsors,
    Stores,
    Analytics,
  }
}
</script>
