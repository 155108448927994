<template>
  <section
    style="max-width: 982px"
    class="mx-auto mt-3 px-3"
    >
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-if="$vuetify.breakpoint.mdAndUp"
        >
        <v-card
          class="rounded-lg"
          flat
          >
          <v-card-text
            class="text--primary"
            >
            ¡Pronto, más y mejores recomendaciones para tí!
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        >
        <search 
          v-if="!$vuetify.breakpoint.mdAndUp"
          style="width: 100%" /> 

        <posts
          explore
          ></posts>
      </v-col>
    </v-row>
  </section>
</template>

<script>
const Search = () => import('@/components/Search')
const Thumb = () => import('@/components/Thumb')
const Posts = () => import('@/components/posts/Posts')

export default {
  name:"Explore",

  components:{
    Search,
    Thumb,
    Posts
  },
}
</script>

<style>

</style>
